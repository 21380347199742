<template>
    <div class="wiki-settings">
      wiki settings
    </div>
  </template>
  
  <script>
  export default {
    name: 'WikiSettings',
  };
  </script>
  
  <style lang="scss">
  .wiki-settings {
    width: 100%;
  }
  </style>
  